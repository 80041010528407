import React from 'react';
import './LandingPage.css';
import Full_W_Art from './Full_W_Art'
import Half_W_Art_01 from './Half_W_Art_01'
import Half_W_Art_02 from './Half_W_Art_02'

const landingPage = props => (
<div className="landingPage">
<div class="container_land">
<div class="jumbotron_land">
<h1>The Public Site</h1>
<h3>Balanced current events analysis and historical review of United States societal issues.</h3>
</div>
</div>

<div class="container_main">
<Full_W_Art />
<div class="row">
<div class="column">
<Half_W_Art_01 />
</div>
<div class="column">
<Half_W_Art_02 />
</div>
</div>
</div>
</div>

);
export default landingPage;
