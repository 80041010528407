import React, { Component } from 'react'
import './Half_W_Art_01.css';
import HalfWArtImg from './Half_W_Art_Com/Half_W_Art_Img_01.jpg';
import HalfWArtTitle from './Half_W_Art_Com/Half_W_Art_Title_01.csv';
import HalfWArtBody from './Half_W_Art_Com/Half_W_Art_Body_01.csv';

class halfWArt extends Component {
  render() {
    return (
      <div className="halfWArt">
       <div class="container_halfWArt">
        <div class="jumbotron_halfWArt">
          <div className="halfWArtTitle">
            The Constitution
            </div>
            <div className="halfWArtImg">
              <img src={HalfWArtImg} />
            </div>
            <div className="halfWArtBody">
            Section 1 All legislative Powers herein granted shall be vested in a Congress of the United States, which shall consist of a Senate and House of Representatives.
           Section 2 1: The House of Representatives shall be composed of Members chosen every second Year by the People of the several States, and the Electors in each State shall have the Qualifications requisite for Electors of the most numerous Branch of the State Legislature.
           2: No Person shall be a Representative who shall not have attained to the Age of twenty five Years, and been seven Years a Citizen of the United States, and who shall not, when elected, be an Inhabitant of that State in which he shall be chosen.
            </div>
          </div>
        </div>
      </div>
      )
    }
  }
export default halfWArt;
