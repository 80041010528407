import React from 'react';
import './Toolbar.css';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';

const toolbar = props => (
  <header className="toolbar">
    <nav className="toolbar_nav">
      <div className="toolbar-toggle-button">
        <DrawerToggleButton click={props.drawerClickHandler}/>
      </div>
      <div className="toolbar_logo"><a href="/">Public Site</a></div>
      <div className="navspace" />
      <div className="toolbar_menu">
        <ul>
          <li><a href="/">Latest</a></li>
          <li><a href="/">Archive</a></li>
          <li><a href="/">Profile</a></li>
        </ul>
      </div>
    </nav>
  </header>
);

export default toolbar;
